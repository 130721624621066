import { useState } from 'react';
import {
  Grid, RadioGroup,  FormControl, TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import SimpleDialog from '../../../../../../components/dialogs/SimpleDialog/SimpleDialog';
import SimpleChip from '../../../../../../components/chips/simple-chip/SimpleChip';
import { modeSchema } from './Mode.schema';
import useDetails from '../../../useDetails';
import useDialog from '../../../../../../hooks/useDialog';
import SimpleModal from '../../../../../../components/dialogs/SimpleModal/SimpleModal';
import { RadioLabel, EditListItem, GoogleMapsAutocomplete, GoogleMapsPreview } from '../../../../../../components/inputs';
import SimpleButton from '../../../../../../components/buttons/simple-button/SimpleButton';
import { PublicationTypeEntity } from '../../../../../../entities/PublicationEntity';
import useAnalytics from '../../../../../../hooks/useAnalytics/useAnalytics';
import getObjectDifferences from '../../../../../../utils/getObjectDifferences';

interface ModeProps {
  form: any;
  disabled?: boolean;
  type?: PublicationTypeEntity;
}

const Mode = ({ form, type, disabled }: ModeProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('my-publication', { keyPrefix: 'details.step-detalles.mode' });
  const items = ['present', 'online', 'semipresent'];
  const { traceBtnClick } = useAnalytics();
  
  const { updateAuthPublication, status, error } = useDetails();
  const { dialog, onCloseDialog } = useDialog(error, 'Error');

  const initialValues = {
    mode: form.values.mode,
    modeDetail: form.values.modeDetail,
    location: form.values.location,
  };
  const localForm = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: modeSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      const newValues = getObjectDifferences(initialValues, {
        ...values,
        location: values.mode === 'online' ? undefined : values.location,
      });
      if (!!newValues) {
        const success = await updateAuthPublication(form?.values?.id, newValues);
        if (!success) {
          return;
        }
        form.setFieldValue('mode', values.mode);
        form.setFieldValue('modeDetail', values.modeDetail );
        form.setFieldValue('location', values.location);
      }
      setOpen(false);
    },
  });

  const handleRadioCheck = (event: any) => {
    localForm.setFieldValue('mode', event.target.value);
  };

  const Content = () => (
    <>
      <SimpleChip label={t(`items.${form.values.mode}`)} maxCharacters={25} />
      {form.values.location?.description && <SimpleChip label={form.values.location.description} maxCharacters={30} /> }
    </>
  );

  return (
    <>
      <SimpleDialog {...dialog} onClose={onCloseDialog} />
      <SimpleModal
        open={open}
        onClose={() => { setOpen(false); }}
        title={t('title')}
        subtitle={t(`subtitle.${type}`, '') || t('subtitle.class')}
      >
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <RadioGroup onChange={handleRadioCheck}>
              {items.map((item) => (
                <RadioLabel
                  key={item}
                  checked={localForm.values.mode === item}
                  value={item}
                  title={t(`items.${item}`)}
                  disabled={disabled || status === 'loading'}
                  descriptionProps={{ fontSize: 13 }}
                  direction='row'
                />
              ))}
            </RadioGroup>
          </Grid>
          {(localForm.values.mode === 'present' || localForm.values.mode === 'semipresent') && (
            <Grid item>
              <FormControl variant="outlined" margin="dense" fullWidth>
                <GoogleMapsAutocomplete 
                  label={t('location') as string}
                  noOptionsText= {t('noOptions') as string}
                  name="location"
                  value={localForm.values.location}
                  onChange={(value) => localForm.setFieldValue('location', value === null ? undefined : value)}
                  error={localForm.touched.location && Boolean(localForm.errors.location)}
                  helperText={localForm.touched.location && localForm.errors.location as string}
                />
              </FormControl>
            </Grid>
          )}
          <Grid item>
            <FormControl variant="outlined" fullWidth>
              <TextField
                name="modeDetail"
                label={t('modeDetail') as string}
                variant="outlined"
                value={localForm.values.modeDetail}
                onChange={localForm.handleChange}
                error={localForm.touched.modeDetail && Boolean(localForm.errors.modeDetail)}
                helperText={localForm.touched.modeDetail && localForm.errors.modeDetail as string}
                inputProps={{ maxLength: 200 }}
              />
            </FormControl>
          </Grid>
          {!!localForm.values.location?.placeId && (localForm.values.mode === 'present' || localForm.values.mode === 'semipresent') && (
            <Grid item>
              <GoogleMapsPreview placeId={localForm.values.location?.placeId} />
            </Grid>
          )}
          <Grid item alignSelf="flex-end">
            <SimpleButton 
              onClick={() => { 
                traceBtnClick({
                  step: 'step guardar mode',
                  product: t('save') + 'item mode',
                });
                localForm.handleSubmit();
              }} 
              disabled={status === 'loading'} 
              loading={status === 'loading'}
            >
              {t('save')}
            </SimpleButton>
          </Grid>
        </Grid>
      </SimpleModal>
      <EditListItem
        label={t('title')}
        onClick={() => { 
          traceBtnClick({
            step: 'step abrir dialogo mode',
            product: 'item mode',
          });
          setOpen(true); 
        }}
        content={form.values.mode && <Content />}
        error={form.touched.mode && Boolean(form.errors.mode)}
        helperText={form.touched.mode && form.errors.mode}
        disabled={disabled}
      />
    </>
  );
};

Mode.displayName = 'Mode';
export default Mode;
