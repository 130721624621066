import React from 'react';
import { Grid } from '@mui/material';
import Subtitle from './Subtitle';
import Paragraph from '../../typographies/Paragraph';

interface Props {
  contents: {
    value: string;
    label: string;
    description: string;
  }[];
}
const VerticalContent = ({ contents }: Props) => {
  return (
    <>
      {contents?.map((content) => (
				  !!content.description ? 
          <React.Fragment key={content.label}>
            <Grid item xs={12}>
              <Subtitle>{content.label}</Subtitle>
            </Grid>
            <Grid item xs={12}>
              <Paragraph>{content.description}</Paragraph>
            </Grid>
          </React.Fragment> : null
      ))}
    </>
  );
};

export default VerticalContent;


