import { Container, Grid, Pagination } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useSearchParams, createSearchParams, useNavigate } from 'react-router-dom';
import PublicationCard from '../../components/cards/publication-card/PublicationCard';
import { SkeletonPublicationCard } from '../../components/cards/publication-card/PublicationCard.styled';
import MainContainer from '../../components/containers/main-container/MainContainer';
import NavigationBar from '../../components/navigations/navigation-bar/NavigationBar';
import { SkeletonText } from '../../components/typographies/title/Title.styled';
import { PublicationEntity } from '../../entities';
import { Search } from './components/search/Search.component';
import usePublications from './usePublications';
import getPublicationIdentifier from '../../utils/getPublicationIdentifier';
import PageSubtitle from '../../components/typographies/PageSubtitle';
import useAnalytics from '../../hooks/useAnalytics/useAnalytics';

const Publications = () => {
  const { fetchPublications, data, count, status, page, totalPages } = usePublications();
  const [filter, setFilter] = useState({ page: '', per_page: '' });

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { traceBtnClick } = useAnalytics();

  const categoryIdParam: number | null = Number(searchParams.get('categoryId') || null);
  const searchParam: string | null = searchParams.get('search');
  const pageParam: string | null = searchParams.get('page');
  const dateParam: string | null = searchParams.get('date');
  const timeParam: string | null = searchParams.get('time');
  const modeParam: string | null = searchParams.get('mode');
  const locationParam: string | null = searchParams.get('location');

  const initialValues = {
    categoryId: categoryIdParam || undefined,
    search: searchParam || undefined,
    date: dateParam || undefined,
    time: timeParam || undefined,
    mode: modeParam || undefined,
    location: locationParam || undefined,
  };

  const onSubmit = (values: any) => {
    // if (!values.search && !values.categoryId) {
    //   return;
    // }
    const params = {
      ...(values.categoryId && { categoryId: values.categoryId }),
      ...(values.search && { search: values.search }),
      ...(values.date && { date: values.date }),
      ...(values.time && { time: values.time.toString() }),
      ...(values.mode && { mode: values.mode }),
      ...(values.location && { location: values.location }),
    };
    navigate(`/publications?${params && createSearchParams(params)}`);
  };

  useEffect(() => {
    setFilter({
      ...(categoryIdParam && { categoryId: categoryIdParam }),
      ...(searchParam && { search: searchParam }),
      ...(timeParam && { search: timeParam }),
      per_page: '8',
      page: `${(parseInt(pageParam || '1', 10))}`,
    });
  }, [searchParams]);

  useEffect(() => {
    if (filter.page) {
      fetchPublications(`?${filter && createSearchParams(filter)}`);
    }
  }, [filter]);

  const handleClickPage = (event: ChangeEvent<unknown>, pageEvent: number) => {
    traceBtnClick({
      step: 'step cambiar página',
      product: pageEvent.toString(),
    });
    fetchPublications(`?${filter && createSearchParams({
      ...filter,
      page: `${pageEvent}`,
    })}`);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleClickPublication = (publication: PublicationEntity) => {
    traceBtnClick({
      step: 'step ver publicación',
      product: getPublicationIdentifier(publication),
    });
    navigate(`/publication/${getPublicationIdentifier(publication)}`, {
      state: { from: '/publications' },
    });
  };
  
  return (
    <MainContainer showFooter>
      <NavigationBar variant="variant2" />
      <Container maxWidth="lg">
        <Search onSubmit={onSubmit} initialValues={initialValues} />
        <PageSubtitle textAlign={count === 0 ? 'center' : undefined}>
          {status === 'loading' ? (
            <SkeletonText />
          ) : count ? (
            `Encontramos ${count} opc${count > 1 ? 'iones' : 'ión'} para ti`
          ) : (
            'No encontramos opciones que coincidan con tu búsqueda. Intenta con otros términos.'
          )}
        </PageSubtitle>
       
        {status === 'loading' && (
          <Grid container direction="row" spacing={4} paddingTop={2}>
            {[...Array(4)].map((_, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <SkeletonPublicationCard />
              </Grid>
            ))}
          </Grid>
        )}
        {status !== 'loading' && data && data?.length > 0 && (
          <Grid container direction="row" spacing={2} paddingTop={2}>
            {data?.map((publication: PublicationEntity) => (
              <Grid key={publication.id} item xs={12} sm={6} md={4} lg={3}>
                <PublicationCard
                  publication={{
                    ...publication,
                    state: undefined,
                  }}
                  onClick={publication?.active ? () => handleClickPublication(publication) : undefined}
                  header={!publication?.hasQuotaAvailable ? 'Sin cupos disponibles' : undefined}
                />
              </Grid>
            ))}
            <Grid item xs={12} display="flex" justifyContent="center" marginTop={1} marginBottom={4}>
              {page && (
                <Pagination
                  count={totalPages}
                  page={page}
                  color="primary"
                  onChange={handleClickPage}
                />
              )}
            </Grid>
          </Grid>
        )}
      </Container>
    </MainContainer>
  );
};

export default Publications;