import * as validator from 'yup';
import i18n from 'i18next';
import emailSchema from '../../../schemas/email/emailSchema';
import { validateRut } from '../../../utils/rutUtilities';

const isRutValid = (value?: string) => !!(value && validateRut(value));

export const identificationSchema = validator.object({
  name: validator
    .string()
    .required(i18n.t('schemas:name:required')),
  lastname: validator
    .string()
    .required(i18n.t('schemas:lastname:required')),
  rut: validator
    .string()
    .required(i18n.t('schemas:rut:required'))
    .test('isRutValid', i18n.t('schemas:rut:notValid'), isRutValid),
  email: emailSchema,
  phoneNumber: validator
    .string()
    .required(i18n.t('schemas:phoneNumber:required'))
    .min(7, i18n.t('schemas:phoneNumber:notValid'))
    .max(15, i18n.t('schemas:phoneNumber:notValid')),
  address: validator
    .string()
    .required(i18n.t('schemas:location:required')),
});
