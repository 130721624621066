import { useContext, useState } from 'react';
import CartContext from '../../../contexts/CartContext';
import { checkout } from '../../../services/checkout';
import { deconstructRut } from '../../../utils/rutUtilities';

interface StateProps {
  status: 'idle' | 'loading' | 'redirect';
  error?: any;
  redirect?: string;
  id?: number;
}

const useIdentification = () => {
  const [state, setState] = useState<StateProps>({ status: 'idle', error: null, redirect: undefined });
  const { cart = [] } = useContext(CartContext);

  const doCheckout = async (value: any) => {
    setState({ status: 'loading' });
    try {
      const rut = deconstructRut(value.rut);
      const items = cart.map((item) => ({
        quantity: item.quantity,
        groupId: item.group?.id,
      }));
      const payload = {
        isGif: value.isGif,
        gifName: value.gifName,
        gifMessage: value.gifMessage,
        payer: {
          name: value.name,
          lastname: value.lastname,
          rut: `${rut.digits}${rut.verifier}`,
          email: value.email,
          phoneNumber: value.phoneNumber,
          address: value.address,
        },
        items,
      };
      const { redirect, id } = await checkout(payload);
      setState({ status: 'redirect', redirect, id });
    } catch (error) {
      setState({ status: 'redirect', error });
    }
  };

  return {
    ...state,
    doCheckout,
  };
};

export default useIdentification;
