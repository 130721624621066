import ScrollTopButton from '../buttons/scroll-top-button/ScrollTopButton';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import useBodyHasScroll from '../../hooks/useBodyHasScroll';
import { Container, Divider, Grid, Link, Typography } from '@mui/material';
import { FooterBox } from './Footer.styled';

const Footer = () => {
  const { hasScroll } = useBodyHasScroll();

  interface FooterSections {
    title: string,
    links?: { text: string, href?: string, icon?: any }[],
  }
  const footerSections: FooterSections[] = [
    {
      title: 'Para Profesores',
      links: [
        { text: 'Empezar a vender', href: '/be-guru' },
      ],
    },
    {
      title: 'Para Exploradores',
      links: [
        { text: 'Explorar y comprar', href: '/' },
      ],
    },
    {
      title: 'Soporte',
      links: [
        { text: 'Preguntas frecuentes', href: '/faqs' },
        { text: 'Términos y condiciones', href: '/terms-and-conditions' },
        { text: 'Contacto', href: 'mailto:contacto@gurapp.cl' },
      ],
    },
    {
      title: 'Medios de pago',
      links: [
        { text: 'Tarjeta de crédito' },
        { text: 'Tarjeta de débito o prepago' },
        { text: 'Mercadopago' },
      ],
    },
    {
      title: 'Síguenos',
      links: [
        { text: 'Instagram', href: 'https://www.instagram.com/gurappcl', icon: InstagramIcon },
        { text: 'Facebook', href: 'https://www.facebook.com/gurappcl', icon: FacebookIcon },
      ],
    },
  ];

  const copyrightSection = `${String.fromCodePoint(169)} ${new Date().getFullYear()} GurApp. Todos los derechos reservados`;
  
  const columns = 12 / footerSections.length;
  return (
    <FooterBox component="footer">
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="space-evenly">
          {footerSections.map((section) => (
            <Grid item xs={12} sm={6} md={columns} key={section.title}>
              <Typography variant="h6" color="text.primary.contrastText" gutterBottom>
                {section.title}
              </Typography>
              <ul style={{ listStyle: 'none', padding: 0 }}>
                {section.links?.map((link) => (
                  <li key={link.text}>
                    <Link href={link.href} variant="body2" color="inherit" underline='none' style={{ display: 'flex', alignItems: 'center' }}>
                      {link.icon && <link.icon />}&nbsp;{link.text}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid>
        <Divider sx={{ mt: 4, mb: 2, backgroundColor:'#b5b3d1' }} />
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="body2">
              {copyrightSection}
            </Typography>
          </Grid>
        </Grid>
      </Container>
      {hasScroll && <ScrollTopButton></ScrollTopButton>}
    </FooterBox>
  );
};

export default Footer;