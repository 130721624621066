import { forwardRef } from 'react';
import { ChipProps } from '@mui/material';
import { Chip } from '@mui/material';
import { primaryMain } from '../../../themes/gurapp.theme';

interface ModeChipProps extends ChipProps {
  mode: 'present' | 'semipresent' | 'online';
}

const modes = {
  'present': 'Presencial',
  'semipresent': 'Semi presencial',
  'online': 'Online en vivo',
};

const colors = {
  'present': primaryMain,
  'semipresent': '#FF8A47',
  'online': '#1DB9C3',
};

const ModeChip = forwardRef<HTMLDivElement, ModeChipProps>(
  ({ mode, ...props }, ref) => {
    return (
      <Chip
        ref={ref}
        label={modes[mode] || mode}
        style={{
          marginRight: 4,
          marginTop: 4,
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 4,
          paddingRight: 4,
          backgroundColor: colors[mode] || mode,
          color: '#fff',
        }}
        {...props}
      />
    );
  },
);

export default ModeChip;
