import { forwardRef } from 'react';
import { FormControl, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as validator from 'yup';
import i18n from 'i18next';
import { SimpleButton } from '../../buttons';
import useQuestionAnswer from './useQuestionAnswer';
import useDialog from '../../../hooks/useDialog';
import SimpleDialog from '../../dialogs/SimpleDialog/SimpleDialog';
import Paragraph from '../../typographies/Paragraph';
import useAnalytics from '../../../hooks/useAnalytics/useAnalytics';
import Subtitle from '../publication-template/Subtitle';

const schema = validator.object({
  question: validator
    .string()
    .min(3)
    .max(1000)
    .required(i18n.t('schemas:question:required')),
});

interface Props {
  publicationId: number,
  disabled?: boolean,
}
const Component = forwardRef<HTMLDivElement, Props>(
  ({ publicationId, disabled }, ref) => {
    const { status, error, createQuestion } = useQuestionAnswer();
    const { dialog, onCloseDialog } = useDialog(error, 'Error');
    const { traceBtnClick } = useAnalytics();
    const form = useFormik({
      enableReinitialize: true,
      initialValues: {
        question: '',
      },
      validationSchema: schema,
      validateOnChange: true,
      onSubmit: async (values) => {
        await createQuestion(
          publicationId,
          values.question,
        );
      },
    });
    
    return (
      <div ref={ref}>
        <SimpleDialog {...dialog} onClose={onCloseDialog} />
   			<Subtitle>¿Alguna duda? Pregúntale al Gurú</Subtitle>
        <>
          {(status === 'loaded' && !error) ? <Paragraph color='success'>Pregunta enviada con éxito</Paragraph> : (
            <>
              <FormControl margin="dense" fullWidth>
                <TextField
                  name='question'
                  label='Escribe tu pregunta'
                  variant="outlined"
                  fullWidth
                  value={form.values.question}
                  onChange={form.handleChange}
                  error={form.touched.question && Boolean(form.errors.question)}
                  helperText={form.touched.question && form.errors.question}
                  disabled={status === 'loading'}
                  multiline
                  minRows={1}
                  maxRows={3}
                  inputProps={{ maxLength: 1000 }}
                />
              </FormControl>
              <SimpleButton 
                onClick={() => {
                  traceBtnClick({
                    step: 'step hacer prpregunta',
                    product: 'Preguntar',
                  });
                  form.handleSubmit();
                }} 
                disabled={status === 'loading' || disabled}
                loading={status === 'loading'}
              >
                Preguntar
              </SimpleButton>
            </>
          )}
        </>
      </div>
    );
  });

export default Component;