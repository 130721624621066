import { forwardRef } from 'react';
import { Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserEntity } from '../../../entities';
import styled from '@emotion/styled';
import Paragraph from '../../typographies/Paragraph';


const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

interface SpeakerProps {
  user?: UserEntity,
}

const Speaker = forwardRef<HTMLDivElement, SpeakerProps>(
  ({ user }, ref) => {
    const { t } = useTranslation(['components'], { keyPrefix: 'publication-template' });
    return (
      <div ref={ref}>
        <Paragraph fontWeight={500} fontSize={20} marginBottom={1}>
          {t('speakerTitle') as string}
        </Paragraph>
        <Container style={{ alignItems: 'flex-start' }}>
          <Avatar
            alt={user?.displayName}
            src={user?.photoUrl}
            sx={{ width: 144, height: 144 }}
          />
          <Paragraph>
            <>
              <strong>{`@${user?.displayName} `}</strong>
              {user?.description}
            </>
          </Paragraph>
        </Container>
      </div>
    );
  },
);

export default Speaker;