import { useContext, useEffect, useState } from 'react';
import { Grid, TextField, FormControl, CircularProgress, Container, InputAdornment, Collapse } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { identificationSchema } from './Identification.schema';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../../contexts/AuthContext';
import CartContext from '../../../contexts/CartContext';
import useDialog from '../../../hooks/useDialog';
import SimpleDialog from '../../../components/dialogs/SimpleDialog/SimpleDialog';
import DividedContainer from '../../../components/containers/divided-container/DividedContainer';
import NavigationBar from '../../../components/navigations/navigation-bar/NavigationBar';
import CartStepper from '../../../components/navigations/cart-stepper/CartStepper';
import SimpleButton from '../../../components/buttons/simple-button/SimpleButton';
import GurappIcon from '../../../components/icons/gurapp-icon/GurappIcon';
import useIdentification from './useIdentification';
import { Title } from '../../../components/typographies';
import PageTitle from '../../../components/typographies/PageTitle';
import PageSubtitle from '../../../components/typographies/PageSubtitle';
import useAnalytics from '../../../hooks/useAnalytics/useAnalytics';
import usePurchase from '../../purchase/usePurchase';
import { CheckboxLabel } from '../../../components/inputs';

const Identification = () => {
  const { t } = useTranslation(['checkout']);
  const { authUser } = useContext(AuthContext);
  const { status, error, redirect, id, doCheckout } = useIdentification();
  const { dialog, onCloseDialog } = useDialog(error, 'Error');
  const { cart = [] } = useContext(CartContext);
  const navigate = useNavigate();
  const { traceBtnClick } = useAnalytics();

  useEffect(() => {
    if (cart.length === 0) navigate('/checkout');
  }, []);

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: authUser?.data?.name ?? '',
      lastname: authUser?.data?.lastname ?? '',
      rut: '',
      email: authUser?.data?.email ?? '',
      phoneNumber: authUser?.data?.phoneNumber ?? '',
      address: '',
      isGif: false,
      gifName: '',
      gifMessage: '',
    },
    validationSchema: identificationSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      await doCheckout(values);
    },
  });

  const [countFetchCheckout, setCountFetchCheckout] = useState(0);
  const { fetchCheckout, data, status: statusPurchase } = usePurchase();
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      if (status === 'redirect' && id && statusPurchase === 'idle' && countFetchCheckout < 3) {
        setCountFetchCheckout(countFetchCheckout + 1);
        fetchCheckout(id);
      }
    }
  });

  useEffect(() => {
    if (!data) {
      return;
    }
    if (data?.state === 'paid') {
      navigate('/purchase/success?external_reference=' + data?.id);
    }
    if (data?.state === 'rejected') {
      navigate('/purchase/fail?external_reference=' + data?.id);
    }
  }, [data]);

  useEffect(() => {
    if (status === 'redirect') {
      if (redirect) {
        window.location.href = redirect;
      } else {
        navigate('/purchase/fail');
      }
    }
  }, [status]);

  const handleGoToPayment = () => { 
    traceBtnClick({
      step: 'step continuar a pago',
      product: t('identification.form.paymentButton'),
    });
    form.handleSubmit();
  };

  const handleGoToBack = () => { 
    traceBtnClick({
      step: 'step retroceder a carrito',
      product: t('back'),
    });
    navigate(-1);
  };

  const handleCheckboxCheck = (event: any) => {
    form.setFieldValue('isGif', event.target.checked);
  };

  return (
    <>
      <SimpleDialog {...dialog} onClose={onCloseDialog} />      
      {(status === 'loading' || status === 'redirect') && (
        <Container maxWidth="sm">
          <Grid container paddingTop={16} spacing={3}>
            <Grid item xs={12} textAlign="center">
              <GurappIcon color="#6501A3" width={48}/>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Title variant="variant3">
                <>
                Te estamos redirigiendo al portal de pagos.
                  <br />
                No cierres esta página.
                </>
              </Title>
            </Grid>
            <Grid item xs={12} textAlign="center" paddingTop={3}>
              <CircularProgress />
            </Grid>
          </Grid>
        </Container>
      )}

      {status === 'idle' && (
        <DividedContainer>
          <NavigationBar variant="variant4" />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <CartStepper activeStepIndex={1} />
            </Grid>
            <Grid item xs={12} mt={1}>
              <PageTitle>{t('identification.title')}</PageTitle>
            </Grid>
            <Grid item xs={12} mb={1}>
              <PageSubtitle>{t('identification.description')}</PageSubtitle>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl margin="dense" fullWidth>
                <TextField
                  name="name"
                  label={t('identification.form.name') as string}
                  variant="outlined"
                  value={form.values.name}
                  onChange={form.handleChange}
                  error={form.touched.name && Boolean(form.errors.name)}
                  helperText={form.touched.name && form.errors.name}
                  inputProps={{ maxLength: 100 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl margin="dense" fullWidth>
                <TextField
                  name="lastname"
                  label={t('identification.form.lastname') as string}
                  variant="outlined"
                  value={form.values.lastname}
                  onChange={form.handleChange}
                  error={form.touched.lastname && Boolean(form.errors.lastname)}
                  helperText={form.touched.lastname && form.errors.lastname}
                  inputProps={{ maxLength: 100 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl margin="dense" fullWidth>
                <TextField
                  name="rut"
                  label={t('identification.form.rut') as string}
                  variant="outlined"
                  value={form.values.rut}
                  onChange={form.handleChange}
                  error={form.touched.rut && Boolean(form.errors.rut)}
                  helperText={form.touched.rut && form.errors.rut}
                  inputProps={{ maxLength: 12 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl margin="dense" fullWidth>
                <TextField
                  name="email"
                  type="email"
                  label={t('identification.form.email') as string}
                  variant="outlined"
                  value={form.values.email}
                  onChange={form.handleChange}
                  error={form.touched.email && Boolean(form.errors.email)}
                  helperText={form.touched.email && form.errors.email}
                  inputProps={{ maxLength: 100 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl margin="dense" fullWidth>
                <TextField
                  name="phoneNumber"
                  label={t('identification.form.phoneNumber') as string}
                  fullWidth
                  variant="outlined"
                  value={form.values.phoneNumber}
                  onChange={form.handleChange}
                  error={form.touched.phoneNumber && Boolean(form.errors.phoneNumber)}
                  helperText={form.touched.phoneNumber && form.errors.phoneNumber}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+56</InputAdornment>,
                  }}
                  inputProps={{ maxLength: 15 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl margin="dense" fullWidth>
                <TextField
                  name="address"
                  label={t('identification.form.address') as string}
                  variant="outlined"
                  value={form.values.address}
                  onChange={form.handleChange}
                  error={form.touched.address && Boolean(form.errors.address)}
                  helperText={form.touched.address && form.errors.address}
                  inputProps={{ maxLength: 100 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth onChange={handleCheckboxCheck}>
                <CheckboxLabel
                  checked={form.values.isGif}
                  name="isGif"
                  title={'¿Es un regalo?'}
                  direction='column'
                  description={'Si seleccionas esta opción, recibirás una gift card por cada curso comprado. Estas gift cards se enviarán por correo electrónico y podrás compartirlas con los destinatarios.'}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Collapse in={form.values.isGif}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <FormControl margin="dense" fullWidth>
                  <TextField
                    name="gifName"
                    label={t('identification.form.gifName') as string}
                    variant="outlined"
                    value={form.values.gifName}
                    onChange={form.handleChange}
                    error={form.touched.gifName && Boolean(form.errors.gifName)}
                    helperText={form.touched.gifName && form.errors.gifName}
                    inputProps={{ maxLength: 100 }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl margin="dense" fullWidth>
                  <TextField
                    name="gifMessage"
                    label={t('identification.form.gifMessage') as string}
                    variant="outlined"
                    value={form.values.gifMessage}
                    onChange={form.handleChange}
                    error={form.touched.gifMessage && Boolean(form.errors.gifMessage)}
                    helperText={form.touched.gifMessage && form.errors.gifMessage}
                    inputProps={{ maxLength: 200 }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Collapse>
          <Grid container spacing={1}>
            <Grid item xs={12} display="flex" justifyContent="space-between" mt={2} mb={2}>
              <SimpleButton sx={{ marginRight: 2 }} variant="text" onClick={handleGoToBack} disabled={false} loading={false}>
                {t('back')}
              </SimpleButton>
              <SimpleButton onClick={handleGoToPayment}>
                {t('identification.form.paymentButton')}
              </SimpleButton>
            </Grid>
          </Grid>
        </DividedContainer>
      )}
    </>
  );
};

export default Identification;
