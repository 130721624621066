import { forwardRef, ReactNode, useEffect, useRef, useState } from 'react';
import { Grid, Divider, styled, Container, Rating } from '@mui/material';
import MainContainer from '../../containers/main-container/MainContainer';
import { NavigationBar } from '../../navigations';
import { KnowledgeEntity, PublicationEntity } from '../../../entities';
import { LinkButton } from '../../buttons';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import getNumberFormatCurrency from '../../../utils/getNumberFormatCurrency';
import Speaker from './Speaker';
import HorizontalImages from '../../images/horizontal-images/HorizontalImages';
import NewQuestion from '../question-answer/NewQuestion';
import Answers from '../question-answer/Answers';
import VerticalContent from './VerticalContent';
import Review from './Review';
import Paragraph from '../../typographies/Paragraph';
import useAnalytics from '../../../hooks/useAnalytics/useAnalytics';
import IconInfoMode from './Icons/IconInfoMode';
import IconInfoQuota from './Icons/IconInfoQuota';
import IconInfoAgeranges from './Icons/IconInfoAgeranges';
import IconInfoDuration from './Icons/IconInfoDuration';
import { GoogleMapsPreview } from '../../inputs/google-maps-autocomplete/GoogleMapsAutocomplete';
import Title from './Title';
import Subtitle from './Subtitle';
import SimpleChip from '../../chips/simple-chip/SimpleChip';
import ModeChip from '../../chips/mode-chip/ModeChip';

interface PublicationTemplateProps {
  publication?: PublicationEntity;
  children?: ReactNode;
  disabledActions?: boolean;
}

const RightContainer = styled(Container)`
  border: 1.5px solid rgba(0, 0, 0, 0.40);
  border-radius: 15px;
  overflow: auto;
  position: sticky;
  top: 16px;
`;

export const PublicationTemplate = forwardRef<HTMLDivElement, PublicationTemplateProps>(
  ({ publication, children, disabledActions, ...rest }, ref) => {
    const navigate = useNavigate();
    const { traceBtnClick } = useAnalytics();
    const location = useLocation();
    const { t } = useTranslation(['components'], { keyPrefix: 'publication-template' });

    const refLeftContainer = useRef<HTMLDivElement>(null);
    const [heightLeftContainer, setHeightLeftContainer] = useState<number>(0);
    useEffect(() => {
      if (refLeftContainer.current) {
        setHeightLeftContainer(refLeftContainer.current.clientHeight);
      }
    }, [refLeftContainer]);

    const contents = [{ 
      value: 'detail', 
      label: t('detail'), 
      description: publication?.detail ?? '', 
    }, { 
      value: 'material', 
      label: t('material'),
      description: publication?.material ?? '',
    }, { 
      value: 'knowledge', 
      label: t('knowledge.title'), 
      description: `${publication?.knowledges?.map((knowledge : KnowledgeEntity) => knowledge.name).join(', ')}${publication?.knowledgeDetail ? `\n${publication?.knowledgeDetail}` : ''}`,
    }];

    return (
      <>
        <MainContainer showFooter ref={ref} {...rest}>
          <NavigationBar variant="variant2" />
          <Container maxWidth="lg" disableGutters>
            {location.state?.from === '/publications' && (
              <LinkButton 
                onClick={() => { 
                  traceBtnClick({
                    step: 'step volver a la busqueda',
                    product: t('backSearch'),
                  });
                  navigate(-1);
                }} 
                color='black'
              >
                <Paragraph fontWeight={500}>
                  {t('backSearch')}
                </Paragraph>
              </LinkButton> 
            )}
            <HorizontalImages images={publication?.audiovisuals ?? []} />
            <Grid container columnSpacing={2} ref={refLeftContainer} mt={1.5}>
              <Grid container spacing={1.5} item xs={12} md={children ? 8 : 12} lg={children ? 9 : 12}>
                {!!publication?.mode && ( 
                  <Grid item xs={12} display={'flex'}>
                    <ModeChip mode={publication?.mode} />
                    {!!publication?.location?.areaLevel2 && (
                      <SimpleChip label={publication?.location?.areaLevel2} />
                    )}
                  </Grid>
                )}
               
                {!!publication?.title && (
                  <Grid item xs={12}>
                    <Title>{publication?.title}</Title>
                  </Grid>
                )}
                
                {publication?.evaluationCount && (
                  <Grid item xs={12} display={'flex'}>
                    <Rating
                      value={publication?.evaluation}
                      precision={0.5}
                      readOnly
                      size="small"
                    />
                    <Paragraph fontSize={14} style={{ opacity: 0.6, marginLeft: 4 }}>
                      {` ${publication?.evaluationCount} reseña${publication?.evaluationCount > 1 ? 's' : ''}`} 
                    </Paragraph>
                  </Grid>
                )}
               
                {!!publication?.price && (
                  <Grid item xs={12}>
                    <Paragraph>{`Precio ${getNumberFormatCurrency(publication.price)}`}</Paragraph>
                  </Grid>
                )}

                {!!publication?.description && (
                  <>
                    <Grid item xs={12}>
                      <Subtitle>{t('description')}</Subtitle>
                    </Grid>
                    <Grid item xs={12}>
                      <Paragraph>{publication?.description}</Paragraph>
                    </Grid>
                  </>
                )}

                <Grid item container xs={12} justifyContent={'space-evenly'}>
                  <IconInfoMode publication={publication} />
                  {publication?.agenda === 'scheduled' && (
                    <IconInfoDuration publication={publication} />
                  )}
                  {publication?.agenda === 'scheduled' && (
                    <IconInfoQuota publication={publication} />
                  )}
                  <IconInfoAgeranges publication={publication} />
                </Grid>
              
                {/* <TabContent contents={contents} /> */}
                <VerticalContent contents={contents} />

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                {!!publication?.location && (
                  <>
                    <Grid item xs={12}>
                      <Subtitle>Ubicación</Subtitle>
                    </Grid>
                    <Grid item xs={12}>
                      <Paragraph>{publication?.location?.description}</Paragraph>
                      <Paragraph>{publication?.modeDetail}</Paragraph>
                    </Grid>

                    {!!publication?.location?.placeId && (
                      <Grid item xs={12}>
                        <GoogleMapsPreview placeId={publication?.location?.placeId} height={300}/>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <Speaker user={publication?.createdBy} />
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>
                  
                {!!publication?.reviewsFormat?.length && (
                  <>
                    <Grid item xs={12}>
                      <Subtitle>Reseñas</Subtitle>
                    </Grid>
                    {publication.reviewsFormat.map((review) => (
                      <Grid item xs={12}>
                        <Review
                          key={review.id}
                          evaluation={review.evaluation}
                          comment={review.comment}
                          evaluatedAt={review.evaluatedAt}
                        />
                      </Grid>
                    ))}
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <NewQuestion 
                    publicationId={publication?.id ?? 0}
                    disabled={disabledActions}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Answers questionAnswers={publication?.questionAnswers} />
                </Grid>
              </Grid>

              {!!children && (
                <Grid item xs={12} md={4} lg={3} >
                  <RightContainer 
                    style={{ 
                      maxHeight: heightLeftContainer, 
                    }}
                  >       
                    {children}
                  </RightContainer>
                </Grid>
              )}
            </Grid>
          </Container>
        </MainContainer>
      </>
    );
  });
