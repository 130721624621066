import { Checkbox, FormControlLabel, Typography } from '@mui/material';

interface CheckboxLabelProps {
  title?: string;
  description?: string;
  checked?: boolean;
  name: string;
  direction?: 'row' | 'column';
  titleProps?: any;
  descriptionProps?: any;
  disabled?: boolean;
  style?: any;
}

const RowContainerStyle = { display: 'flex', gap: '4px', alignItems: 'baseline' };
const ColumnContainerStyle =  { alignItems: 'flex-start' };

const CheckboxLabel = ({ 
  checked, 
  name, 
  title, 
  description, 
  direction = 'column', 
  titleProps = {}, 
  descriptionProps = {},
  disabled,
  style,
  ...props
}: CheckboxLabelProps) => (
  <FormControlLabel
    control={(<Checkbox name={name} size="small" checked={checked} disabled={disabled}/>)}
    label={<div style={direction === 'row' ? RowContainerStyle : ColumnContainerStyle}>
      <Typography fontWeight={500} {...titleProps}>{title}</Typography>
      <Typography fontWeight={300} {...descriptionProps}>{description}</Typography>
    </div>}
    style={style}
    {...props}
  />
);

CheckboxLabel.displayName = 'CheckboxLabel';
export default CheckboxLabel;
